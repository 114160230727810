import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import { useLocation } from "@reach/router"

const BlackFridayStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  padding: 2px 20px;
  font-size: 18px;
  text-align: center;
  background: #131112;
  color: #fff;
  position: relative;
  overflow: hidden;

  @media (max-width: 450px) {
    padding: 8px 10px;
    padding: 2px 20px;
  }
`

const LinkBox = styled(Link)`
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
  display: none;
  display: block;

  @media (max-width: 767px) {
    display: block;
  }
`

const LinkButtonTitle = styled.span`
  display: none;
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const MobileButton = styled(Link)`
  display: none;
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translate(0%, -50%);
  font-weight: bold;

  @media (max-width: 600px) {
    display: inline-block;
    position: relative;
    right: 0px;
    top: 0px;
    transform: translate(0px, 0px);
    margin-left: 10px;
  }

  @media (max-width: 400px) {
    margin-left: 5px;
  }
`

const TextBoxWrapper = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;

  @media (max-width: 600px) {
    min-height: 60px;
  }
`

const TextBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 400;
  font-size: 21px;
  line-height: 1.2;
  color: ${props => props.theme.saleColor.textColor};
  text-align: left;
`

const LeftText = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  // background: ${props => props.theme.saleColor.bgTagColor};
  // color: ${props => props.theme.saleColor.textColor};
  // border: ${props => "2px solid" + props.theme.saleColor.borderColor};
  // padding: 7px 8px 6px;
  // border-radius: 10px 0px;

  span {
    display: flex;
    margin-left: 5px;

    @media (max-width: 767px) {
      display: block;
      margin-left: 0px;
    }
  }

  @media (max-width: 767px) {
    font-size: 18px;
    display: inline;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    margin-left: -10px;
    // padding: 5px 6px;
  }

  @media (max-width: 350px) {
      font-size: 16px;
  }
`

const BlackFridayLogo = styled.img`
  width: 100%;
  max-width: 150px;
  margin: 0px;
  object-fit: contain;

  @media (max-width: 380px) {
    max-width: 130px;
  }

  @media (max-width: 350px) {
    max-width: 120px;
  }
`

const BlackFridayDesktopLogo = styled.img`
  width: 100%;
  max-width: 150px;
  margin: 0px;
  object-fit: contain;

  @media (max-width: 600px) {
    display: none;
  }
`

const BlackFridayMobileLogo = styled.img`
  width: 100%;
  display: none;
  max-width: 55px;
  margin: 0px;
  object-fit: contain;

  @media (max-width: 600px) {
    display: block;
  }
`

const FlashText = styled.div`
  font-family: "Gotham";
  font-weight: bold;
  font-size: 16px;
  line-height: 1.2;
  margin: 5px 5px 0px 7px;
  margin: 0px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;

  span:nth-of-type(1) {
    display: block;
    font-size: 14px;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  @media (max-width: 480px) {
    margin: 5px 3px 0px 5px;
    margin: 0px;
    // font-size: 14px;
  }

  @media (max-width: 380px) {
    font-size: 14px;
  }

  @media (max-width: 380px) {
    font-size: 13px;
  }
`

const RightText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  margin-left: ${({ marginLeft }) => (marginLeft ? "10px" : "0px")};
  color: #fff;

  > span:nth-of-type(1) {
    margin: 0px 7px;
    font-size: 24px;
    font-weight: 900;
    padding: 7px 8px 6px;
    border-radius: 10px 0px;
    background: ${props => props.theme.saleColor.bgTagColor};
    background: #bef5f7;
    color: ${props => props.theme.saleColor.textColor};
    color: #131112;

    @media (max-width: 767px) {
      font-size: 18px;
    }

    @media (max-width: 480px) {
      font-size: 18px;
      padding: 5px 6px;
    }

    @media (max-width: 380px) {
      margin: 0px 5px;
      font-size: 16px;
    }
  }

  span > span {
    @media (max-width: 600px) {
      display: block;
    }
  }

  sup {
    margin-right: 4px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    margin-left: ${({ marginLeft }) => (marginLeft ? "7px" : "0px")};
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (max-width: 380px) {
    margin-left: ${({ marginLeft }) => (marginLeft ? "5px" : "0px")};
    font-size: 13px;
  }
`

const Button = styled(Link)`
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;
  font-size: 16px;
  font-family: "Museo";
  font-weight: 700;
  margin-left: 35px;
  text-decoration: underline;
  line-height: 22px;
  display: inline-block;

  @media (max-width: 767px) {
    margin-left: 20px;
  }

  @media (max-width: 600px) {
    display: none;
  }

  :hover {
    color: ${props => props.theme.saleColor.textColor};
    color: #fff;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
`

const CloseButton = styled.div`
  background: none;
  color: ${props => props.theme.saleColor.textColor};
  font-size: 20px;
  padding: 0px;
  box-shadow: none;
  position: absolute;
  line-height: 10px;
  font-weight: normal;
  right: 5px;
  top: 5px;
  margin: 0px;
  cursor: pointer;
  z-index: 1;

  :hover {
    color: ${props => props.theme.saleColor.textColor};
  }
`

const BlackFridayStickyBanner = ({ intl }) => {
  const [LyneHomeStickyHide, setLyneHomeStickyHide] = useState(false)
  const [activeFlag, setactiveFlag] = useState(true)
  const [shopButtonHide, setShopButtonHide] = useState(false)
  const [isLynePage, setisLynePage] = useState(false)
  const [isOldLynePage, setisOldLynePage] = useState(false)
  const [isOreiller, setisOreiller] = useState(false)
  const [isMatelas, setisMatelas] = useState(false)
  const [isSurmatelas, setisSurmatelas] = useState(false)
  const [isSommierPage, setisSommierPage] = useState(false)
  const [isCartPage, setisCartPage] = useState(false)

  const { pathname } = useLocation()

  const checkButtonVisibility = () => {
    let currentPath = ""
    let ButtonHide = false
    let isShopPage = false
    let isCartPage = false

    let regex = /(lyneup|lynefit|lynehome|matelas|surmatelas|oreiller|sommiers-zones|sommier-zone-original|sommier-zone-coffre|sommier-zone-bois-massif|lit-electrique|wearable-technology|solutions-textiles)/
    let lyneRegex = /(lyneup|lynefit|lynehome|wearable-technology|solutions-textiles)/
    let oldLyneRegex = /(wearable-technology-derniere-chance|solutions-textiles-derniere-chance)/
    let oreillerRegex = /(oreiller)/
    let matelasRegex = /(matelas)/
    let surMatelasRegex = /(surmatelas)/
    let sommierRegex = /(sommiers-zones|sommier-zone-original|sommier-zone-coffre|sommier-zone-bois-massif|lit-electrique)/
    let cartRegex = /(panier|cart)/
    if (isBrowser) {
      currentPath = window.location.pathname

      let pathArr = currentPath.split("/")

      if (
        pathArr[pathArr.length - 1] === "shop" ||
        pathArr[pathArr.length - 2] === "shop"
      ) {
        isShopPage = true
      }
      ButtonHide = regex.test(currentPath)
      setShopButtonHide(ButtonHide)
      setisLynePage(lyneRegex.test(currentPath))
      setisOldLynePage(oldLyneRegex.test(currentPath))
      setisOreiller(oreillerRegex.test(currentPath))
      setisMatelas(matelasRegex.test(currentPath))
      setisSurmatelas(surMatelasRegex.test(currentPath))
      setisSommierPage(sommierRegex.test(currentPath))
      setisCartPage(cartRegex.test(currentPath))
    }
  }

  const hideBanner = () => {
    isBrowser && sessionStorage.setItem("MaiStickyBanner", true)
    setLyneHomeStickyHide(true)
  }
  const checkVisibilityBanner = () => {
    if (isBrowser) {
      sessionStorage.getItem("MaiStickyBanner") && setLyneHomeStickyHide(true)
    }
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      // if the element doesn't exist, abort
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }
    $(window).resize(() => {
      var w = window.outerWidth
      if (w > 600) {
        if (isOnScreen($(".HomeVideoBanner"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(false)
        } else {
          setactiveFlag(true)
        }
        window.addEventListener("scroll", function(e) {
          if (isOnScreen($(".HomeVideoBanner"))) {
            /* Pass element id/class you want to check */
            setactiveFlag(false)
          } else {
            setactiveFlag(true)
          }
        })
      }
    })

    $(document).ready(function() {
      var w = window.outerWidth
      if (w > 600) {
        if (isOnScreen($(".HomeVideoBanner"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(false)
        } else {
          setactiveFlag(true)
        }
        window.addEventListener("scroll", function(e) {
          if (isOnScreen($(".HomeVideoBanner"))) {
            /* Pass element id/class you want to check */
            setactiveFlag(false)
          } else {
            setactiveFlag(true)
          }
        })
      }
    })
  }

  useEffect(() => {
    checkVisibilityBanner()
    // BottomBarActive()
  }, [intl.locale])

  useEffect(() => {
    isBrowser && checkButtonVisibility()
  }, [pathname])
  return (
    !LyneHomeStickyHide &&
    activeFlag && (
      <BlackFridayStickyBannerWrapper
        className="LyneHomeStickyBanner"
        page={isLynePage}
      >
        {shopButtonHide ? (
          <Container>
            {isOldLynePage ? (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/ab63ef88-b4f4-4cad-ae38-e98ced70a2aa.gif"
                  />
                  <FlashText>Derniers Jours</FlashText>
                </LeftText>
                <RightText>
                  <span>-80%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isLynePage ? (
              <TextBox>
                <LeftText>
                  {/* <BlackFridayDesktopLogo
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/ab63ef88-b4f4-4cad-ae38-e98ced70a2aa.gif"
                  />
                  <BlackFridayMobileLogo
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/837d9f2d-9245-4c5a-90a1-6b3a8ab9ca55.gif"
                  /> */}
                  <BlackFridayLogo
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/ab63ef88-b4f4-4cad-ae38-e98ced70a2aa.gif"
                  />
                  <FlashText>Derniers Jours</FlashText>
                </LeftText>
                {/* <FlashText>
                  Offre flash
                  <span>jusqu’au 28/11</span>
                </FlashText> */}
                <RightText>
                  <span>-50%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isOreiller ? (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/ab63ef88-b4f4-4cad-ae38-e98ced70a2aa.gif"
                  />
                  <FlashText>Derniers Jours</FlashText>
                </LeftText>
                <RightText>
                  <span>-50%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isSurmatelas ? (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/ab63ef88-b4f4-4cad-ae38-e98ced70a2aa.gif"
                  />
                  <FlashText>Derniers Jours</FlashText>
                </LeftText>
                <RightText>
                  <span>-20%</span>
                  <span>
                    sur le <span>surmatelas</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isMatelas ? (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/ab63ef88-b4f4-4cad-ae38-e98ced70a2aa.gif"
                  />
                  <FlashText>Derniers Jours</FlashText>
                </LeftText>
                <RightText>
                  <span>-35%</span>
                  <span>
                    sur le pack <span>matelas + oreiller</span>
                    {/* sur le <span>matelas</span> */}
                  </span>
                </RightText>
              </TextBox>
            ) : isSommierPage ? (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/ab63ef88-b4f4-4cad-ae38-e98ced70a2aa.gif"
                  />
                  <FlashText>Derniers Jours</FlashText>
                </LeftText>
                <RightText>
                  <span>-35%</span>
                  <span>
                    sur le pack <span>matelas + sommier</span>
                  </span>
                </RightText>
              </TextBox>
            ) : (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/ab63ef88-b4f4-4cad-ae38-e98ced70a2aa.gif"
                  />
                  <FlashText>Derniers Jours</FlashText>
                </LeftText>
                <RightText>
                  <span>-50%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
              </TextBox>
            )}
          </Container>
        ) : (
          <Container>
            <TextBoxWrapper>
              <TextBox>
                {!isCartPage && (
                  <LinkBox to="/shop/solutions-textiles-derniere-chance/">
                    <LinkButtonTitle>Je découvre</LinkButtonTitle>
                  </LinkBox>
                )}
                <LeftText>
                  <BlackFridayLogo
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/ab63ef88-b4f4-4cad-ae38-e98ced70a2aa.gif"
                  />
                  <FlashText>Derniers Jours</FlashText>
                </LeftText>
                <RightText marginLeft="10px">
                  Jusqu’à
                  <span>-80%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
                {!isCartPage && (
                  <Button to="/shop/solutions-textiles-derniere-chance/">
                    J'en profite {">"}
                  </Button>
                )}
                {!isCartPage && (
                  <MobileButton to="/shop/solutions-textiles-derniere-chance/">
                    {">"}
                  </MobileButton>
                )}
              </TextBox>
            </TextBoxWrapper>
          </Container>
        )}
        {/* <CloseButton onClick={() => hideBanner()}>×</CloseButton> */}
      </BlackFridayStickyBannerWrapper>
    )
  )
}

export default injectIntl(BlackFridayStickyBanner)
